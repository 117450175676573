import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { BrowserStorageService, SignInReadableService } from '@core/application';
import { ResponseStatus } from '@infrastructure/message';
import { catchError, from, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment.development';
import { Router } from '@angular/router';
import { Constant } from '@infrastructure/xhelper';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const platformId = inject(PLATFORM_ID);
  if (isPlatformBrowser(platformId)) {
    const signInService = inject(SignInReadableService);
    const storage = inject(BrowserStorageService);
    const router = inject(Router);
    const accessToken = storage.get(Constant.ACCESS_TOKEN) || '';
    const refreshToken = storage.get(Constant.REFRESH_TOKEN) || '';
    const isPublicApi = environment.publicApis.some((endpoint) => req.url.includes(endpoint));

    if (accessToken) {
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return next(authReq).pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse && !isPublicApi) {
            if (err.status === 401) {
              signInService.endPoint = environment.apiUrl;
              return from(signInService.refreshToken(refreshToken)).pipe(
                switchMap((res) => {
                  if (res.refreshTokenResponse && res.status == ResponseStatus.SUCCESS) {
                    storage.set(Constant.ACCESS_TOKEN, res.refreshTokenResponse.accessToken);
                    const retryReq = req.clone({
                      setHeaders: {
                        Authorization: `Bearer ${res.refreshTokenResponse.accessToken}`,
                      },
                    });
                    return next(retryReq);
                  } else {
                    storage.removeItem(Constant.ACCESS_TOKEN);
                    storage.removeItem(Constant.REFRESH_TOKEN);
                    router.navigateByUrl('/sign-in');
                    return throwError(() => err);
                  }
                })
              );
            }
          }

          return throwError(() => err);
        })
      );
    }
  }
  return next(req);
};
